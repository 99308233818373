import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { MainPageService } from '../../services';

import { FacilityData, AnswersList } from '../../models';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  private tokenId: string | null = this.activRoute.snapshot.paramMap.get('tokenId');

  step: number = 1;
  choisList: Array<{ id: string, value: string }> = [
    {
      id: 'veryBad',
      value: 'Very bad',
    },
    {
      id: 'bad',
      value: 'Bad',
    },
    {
      id: 'average',
      value: 'Average',
    },
    {
      id: 'good',
      value: 'Good',
    },
    {
      id: 'veryGood',
      value: 'Very Good',
    },
  ];

  private answer5: Array<string> = [];
  answersHearAboutFacility: Array<{ value: string; title: string; }> = [
    {
      value: 'Google and Search Engines',
      title: 'Google and Search Engines',
    },
    {
      value: 'Emails or Newsletter',
      title: "Quick'rCare",
    },
    {
      value: 'Friends or other people',
      title: 'Friends or other people',
    },
    {
      value: 'Billboard',
      title: 'Billboard',
    },
    {
      value: 'Print Advertising',
      title: 'Other',
    },
  ];

  form: FormGroup = this.formBuilder.group({
    question1: ['', Validators.required],
    question2: ['', Validators.required],
    question3: ['', Validators.required],
    question4: ['', Validators.required],
    questionTemp: ['', Validators.required],
    description1: [''],
    description2: [''],
    description3: [''],
    description4: [''],
  });

  submitted: boolean = false;
  get ctrlForm() {
    return this.form.controls;
  }

  facilityData: FacilityData;
  showForm: boolean = false;
  showSystemMessage: boolean = false;

  systemMessage: string = 'Sorry, but the service is currently unavailable.';

  constructor(
    private formBuilder: FormBuilder,
    private mainPageService: MainPageService,
    private activRoute: ActivatedRoute,
  ) {
    this.facilityData = {
      name: '',
    };
  }

  ngOnInit(): void {
    this.mainPageService.getFacilityData(this.tokenId).subscribe((res) => {
      if (res.status) {
        this.facilityData = res.data;

        this.showForm = true;
      }

      if (!res.status) {
        this.showForm = false;
        this.showSystemMessage = true;
        this.systemMessage = res.message || 'Sorry, but the service is currently unavailable.';
      }
    }, (error) => {
      this.showForm = false;
      this.showSystemMessage = true;
    });
  }

  currentProgress(): string {
    return `progress-${this.step}`;
  }

  prevStep(): void {
    if (this.step > 1) {
      this.step = this.step - 1;
    }
  }

  nextStep(): void {
    if (this.step <= 5) {
      this.submitted = true;

      if (this.form.get(`question${this.step}`)?.errors === null) {
        this.submitted = false;
        this.step = this.step + 1;
      }

      if (this.step === 5 && this.form.get('questionTemp')?.errors === null) {
        this.submitted = false;
        this.step = this.step + 1;
      }
    }
  }

  save(value: AnswersList, valid: boolean): void {
    this.submitted = !valid;

    if (valid) {
      this.saveAnswers(value);
    }
  }

  private saveAnswers(values: AnswersList): void {
    const sendData = { question5: this.answer5, ...values };

    this.mainPageService.saveAnswers(sendData, this.tokenId).subscribe((res) => {
      Swal.fire({
        html: res.message,
        icon: res.status ? 'success' : 'warning',
        showConfirmButton: true,
      })
      .then(() => {
        window.location.reload();
      });
    });
  }

  selectHearAboutFacility($event: boolean, value: string): void {
    if ($event) {
      this.answer5.push(value);
    }

    if (!$event) {
      this.answer5 = this.answer5.filter((item) => item !== value);
    }
  }
}
