import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env';

@Injectable()
export class FullUrlInterceptor implements HttpInterceptor {
  private apiUrl: string = environment.apiUrl;

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloneRequest = request.clone({ url: `${this.apiUrl}/widgets/review/${request.urlWithParams}` });

    return next.handle(cloneRequest);
  }
}
