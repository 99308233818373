import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResFacilityData, ResMessage, AnswersList } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MainPageService {

  constructor(
    private http: HttpClient,
  ) { }

  getFacilityData(token: string | null): Observable<ResFacilityData> {
    return this.http.get<ResFacilityData>(`facility-data/${token}`);
  }

  saveAnswers(data: AnswersList, token: string | null): Observable<ResMessage> {
    return this.http.post<ResMessage>(`save-answers/${token}`, data);
  }
}
