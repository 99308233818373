<div class="app-section">
  <div class="header">
    <a href="https://quickrcare.com/" target="_blank" rel="noopener noreferrer">
      <img src="/assets/images/logo_new.png" alt="Quick’rCare">
    </a>
  </div>
  <div class="content-body">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <a href="https://quickrcare.com/" target="_blank" rel="noopener noreferrer">
      ©{{year}} Quick’rCare
    </a>
  </div>
</div>
